import { FaSolarPanel } from 'react-icons/fa'

const benefies = [
  { title: 'PRODUCES ENERGÍA LIMPIA', text: 'Por cada 100 kWh que produces, CFE deja de emitir 62.5 kilogramos de CO2 al medio ambiente.' },
  { title: 'DEDUCES LA INVERSIÓN', text: 'De acuerdo al Art. 34, fracción XIII de la Ley del ISR, se puede deducir en un ejercicio fiscal el 100% de la inversión.' },
  { title: 'ADQUIERES EQUIPO DE CALIDAD', text: 'Con nosotros siempre tendrás las mejores marcas a nivel mundial y la última tecnología en equipo fotovoltaico.' },
  { title: 'AHORRAS HASTA 98% EN TU PAGO', text: 'En hogares con Tarifa DAC, al colocar paneles puedes regresar a la tarifa subsidiada de CFE.' },
  { title: 'MONITOREAS LA PRODUCCIÓN', text: 'A través de una App puedes estar al tanto del funcionamiento de tus paneles solares 24/7' },
  { title: 'DAS PLUSVALÍA', text: 'La utilización de ecotecnologías incrementa considerablemente el valor de tu propiedad.' }
]

const TitleSection = ({ title }) => (
  <div className='flex items-center justify-center'>
    <div className='grid justify-items-center sm:md:m-10 sm:md:p-10 m-2 p-2 container backdrop-blur-md '>
      <h2 className='sm:md:text-5xl text-3xl text-slate-900/80'>{title}</h2>
    </div>
  </div>
)

const Benefit = ({ title, text }) => (
  <div className='grid justify-items-center sm:md:m-10 sm:md:p-10 m-2 p-2 container backdrop-blur-md hover:backdrop-brightness-50 hover:scale-95 transition-transform delay-150 hover:text-white text-slate-800/80'>
    <h3 className='sm:md:text-5xl text-3xl sm:md:mx-10 sm:md:px-10 mx-2 px-2 py-2 flex items-center backdrop-blur-xl font-medium font-sans '><FaSolarPanel className='text-5xl mx-3' /> {title}</h3>
    <p className='sm:md:text-xl text-sm font-medium sm:md:mx-10 sm:md:px-10 mx-2 px-2 mb-10 p-10 backdrop-blur-xl'>{text}</p>
  </div>
)

const Benefits = () => {
  return (
    <div className='bg-neutral-100 text-left'>
      <TitleSection title='¿POR QUÉ INVERTIR EN PANELES SOLARES INVERSOL?' />
      <div className='grid sm:md:lg:grid-cols-2 grid-cols-1 gap-4 mx-auto container'>
        <Benefit title={benefies[0].title} text={benefies[0].text} />
        <Benefit title={benefies[1].title} text={benefies[1].text} />
        <Benefit title={benefies[2].title} text={benefies[2].text} />
        <Benefit title={benefies[3].title} text={benefies[3].text} />
        <Benefit title={benefies[4].title} text={benefies[4].text} />
        <Benefit title={benefies[5].title} text={benefies[5].text} />
      </div>
    </div>
  )
}

export default Benefits
