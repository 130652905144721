import { FaFacebook } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'
import Benefits from './assets/components/Benefits'
import Footer from './assets/components/Footer'
import Header from './assets/components/Header'
import Contact from './assets/components/Contact'

const App = () => {
  return (
    <div className='flex flex-col bg-slate-50 w-full h-screen text-center'>
      <Header title='Inversol' text='Somos una empresa mexicana especializada en asesorar, diseñar e instalar sistemas de generación de energía, calentamiento de agua y aprovechamiento de calor para producir electricidad en residencias, gasolineras, industrias y comercios, de la manera óptima y eficiente.' />
      <Benefits />
      <div className='w-full bg-sky-900/80'>
        <div className='grid justify-items-center p-2'>
          <div className='flex items-center'>
            <a href='https://www.facebook.com/dmgroup.mx'><FaFacebook className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-sky-900/80 hover:bg-white text-white bg-sky-900/80 focus:outline-none focus:ring-6 focus:ring-sky-300 rounded-full dark:focus:ring-sky-900/80' /></a>
            <a href='https://linkedin.com/company/dm1group'><BsLinkedin className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-sky-900/80 hover:bg-white text-white bg-sky-900/80 focus:outline-none focus:ring-6 focus:ring-sky-300 rounded-full dark:focus:ring-sky-900/80' /></a>
          </div>
        </div>
      </div>
      <Contact />
      <Footer className='h-10' />
    </div>
  )
}

export default App
