import { FaSolarPanel } from 'react-icons/fa'
import bgcity from '../files/bg-header4.jpeg'
import logo from '../files/logo.png'

// const benefies = [
//   { title: 'PRODUCES ENERGÍA LIMPIA', text: 'Por cada 100 kWh que produces, CFE deja de emitir 62.5 kilogramos de CO2 al medio ambiente.' },
//   { title: 'DEDUCES LA INVERSIÓN', text: 'De acuerdo al Art. 34, fracción XIII de la Ley del ISR, se puede deducir en un ejercicio fiscal el 100% de la inversión.' },
//   { title: 'ADQUIERES EQUIPO DE CALIDAD', text: 'Con nosotros siempre tendrás las mejores marcas a nivel mundial y la última tecnología en equipo fotovoltaico.' },
//   { title: 'AHORRAS HASTA 98% EN TU PAGO', text: 'En hogares con Tarifa DAC, al colocar paneles puedes regresar a la tarifa subsidiada de CFE.' },
//   { title: 'MONITOREAS LA PRODUCCIÓN', text: 'A través de una App puedes estar al tanto del funcionamiento de tus paneles solares 24/7' },
//   { title: 'DAS PLUSVALÍA', text: 'La utilización de ecotecnologías incrementa considerablemente el valor de tu propiedad.' }
// ]

const Header = ({ text = '', title = '' }) => {
  return (
    <header>
      <div
        className='w-full bg-cover min-h-1/2 mx-h-1/2 bg-center grid justify-items-center backdrop-gray-900'
        style={{ backgroundImage: `url(${bgcity})` }}
      >
        <div className='flex items-center justify-center h-full w-full '>
          <div className='grid justify-items-center sm:md:m-10 sm:md:p-10 m-2 p-2 container backdrop-blur-md '>
            <img className='h-2/3' alt='' src={logo} />
            {title.length > 0 && (
              <p className='text-white/80 sm:md:text-5xl text-3xl sm:md:mx-10 sm:md:px-10 mx-2 px-2 py-2 flex items-center backdrop-blur-xl font-medium font-sans '><FaSolarPanel className='text-5xl mx-3 text-white/80' /> {title}</p>
            )}
            {text.length > 0 && (
              <p className='text-white/90 sm:md:text-xl text-sm font-medium sm:md:mx-10 sm:md:px-10 mx-2 px-2 mb-10 p-10 backdrop-blur-xl'>{text}</p>
            )}
          </div>
          {/* {benefies} */}
        </div>
      </div>
    </header>
  )
}

export default Header
